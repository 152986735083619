@import "../../assets/css/_general";

.tech-icon {
  width: fit-content;
  width: 60px;
  height: fit-content;
  // margin: 0 auto

  img {
    height: 50px;
    width: auto;
    margin: 0 auto;
    display: block; }

  p {
    margin-top: 5px;
    text-align: center;
    font-weight: bold;
    font-size: .9rem; } }
