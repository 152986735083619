@import "../../assets/css/_general";

.contacts-sidebar {
  width: 50px;
  height: calc( 100vh - 30px );
  // box-sizing: border-bo
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;

  .bar {
    height: 60%;
    background-color: $light-lazulite;
    width: 1px; }

  a {
    color: $light-grey; } }
