.carousel.carousel-slider {
  max-height: 300px;
  height: 100%;
  max-width: 550px;
  width: 100%;
  border-radius: 5px; }

.carousel {
  max-width: 550px;
  width: 100%; }

.slide {
  img {
    border-radius: 5px;
    object-fit: cover;
    min-height: 100%;
    width: 100%; } }
