@import "../../assets/css/_general";

.profile {
  background-color: $dark-lazulite;
  height: 100%;
  padding-top: 25px;
  box-sizing: border-box;

  &__wrap {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    @include md {
      padding-top: 50px;
      flex-direction: column; }

    .side__bar {
      background-color: $light-lazulite;
      position: absolute;
      opacity: .8;
      bottom: 5%;
      left: 25px;
      height: 90%;
      width: 1px; }

    .dashed__bar {
      position: absolute;
      opacity: .8;
      top: 80px;
      left: 50%;
      height: 80%;
      border-left: dashed 1px $light-lazulite;
      -ms-transform: rotate(45deg); //IE
      -webkit-transform: rotate(45deg); //Safari prior 9.0
      transform: rotate(45deg); } //Standard syntax

    .solid__bar {
      position: absolute;
      opacity: .8;
      top: 80px;
      left: 50.5%;
      height: 80%;
      border-left: solid 1px $light-lazulite;
      -ms-transform: rotate(45deg); //IE
      -webkit-transform: rotate(45deg); //Safari prior 9.0
      transform: rotate(45deg); } //Standard syntax

    .bottom__bar {
      opacity: .8;
      position: absolute;
      color: $light-grey;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 10px;
      width: 100%;
      bottom: 4.5%;
      margin: 0 10px;

      .bar {
        width: 80%;
        height: 1px;
        background-color: $light-lazulite; } }

    .dashed__bar, .solid__bar {
      @include md {
        display: none; } }

    .side__bar, .bottom__bar {
      @include lg {
        display: none; } } }

  &__description, &__skills {
    width: 50%;
    min-height: 500px;
    box-sizing: border-box;

    @include md {
      width: 100%;
      min-height: 350px; } }

  &__description {
    padding: 70px;
    display: flex;
    flex-direction: column;
    text-align: justify;
    color: $light-grey;

    @include lg {
      padding: 20px; }

    &__title {
      width: fit-content;
      margin-bottom: 15px;
      text-align: center;

      @include md {
        text-align: left; }

      h5 {
        margin-bottom: 10px; }

      .bar {
        width: 100%;
        height: 1px;
        background-color: $light-lazulite; } }

    &__contacts {
      width: 100px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        color: $light-grey; } }

    &__summary {
      margin-bottom: 80px;

      @include md {
        margin-bottom: 50px; } }

    &__actions {

      .button {
        padding: 0 15px;
        border: solid 2px $light-grey;
        color: $light-grey;
        font-weight: bold;
        background-color: transparent;
        cursor: pointer; }

      .ball {
        margin-top: 40px;
        margin-left: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $light-lazulite;

        @include md {
          margin-top: 20px; } } } }

  &__skills {
    padding: 20px 0;
    padding-right: 70px;
    color: $light-grey;
    height: 500px;

    @include lg {
      padding: 0 20px; }

    @include md {
      display: none; }

    &__title {
      opacity: .1;
      text-align: right;
      font-weight: bold; }

    &__wrap {
      padding: 20px 10px 0px 15px;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include lg {
        padding: 0px; }

      h4 {
        position: absolute;
        text-align: right;
        margin-bottom: auto;
        font-weight: bold;
        right: calc( 100% + 5px ); }

      h3:not(:last-child) {
        margin-bottom: 5px; }

      .improving, .skills, .languages {
        position: relative;
        width: 30%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box; }

      .improving {
        height: 30%;
        margin-top: 90px; }

      .skills {
        height: 60%; }

      .languages {
        height: 90%; } } } }

