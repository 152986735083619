// Primary Colors
$light-grey: #aaabb8;
$dark-grey: #464866;
$dark-lazulite: #25274d;
$light-lazulite: #29648a;
$aquablue: #2e9cca;

// General Colors
$warning: #ffd700;
$info: #3399ff;
$danger: #ff7373;
