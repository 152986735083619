@import "../../../assets/css/_general";

.navbar {
  width: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 20;

  &__item {
    color: $light-grey;
    text-decoration: none; }

  &__left {
    width: 50%; }

  &__right {
    width: 50%;
    display: flex;
    justify-content: space-evenly; } }
