@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.navbar {
  width: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 20; }
  .navbar__item {
    color: #aaabb8;
    text-decoration: none; }
  .navbar__left {
    width: 50%; }
  .navbar__right {
    width: 50%;
    display: flex;
    justify-content: space-evenly; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.homepage {
  height: 100%;
  display: flex; }
  @media all and (max-width: 768px) {
    .homepage {
      flex-direction: column; } }
  .homepage .intro {
    padding-top: 35px;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    min-height: 500px;
    background-color: #25274d;
    color: #aaabb8;
    display: flex; }
    @media all and (max-width: 768px) {
      .homepage .intro {
        width: 100%;
        order: 2; } }
    .homepage .intro__contacts {
      min-width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center; }
      .homepage .intro__contacts .bar {
        height: 60%;
        background-color: #29648a;
        width: 1px; }
      .homepage .intro__contacts a {
        color: #aaabb8; }
    .homepage .intro__summary {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      padding: 40px 20px;
      box-sizing: border-box;
      max-height: 560px;
      height: 80%; }
      .homepage .intro__summary__about {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
        width: 100%; }
        .homepage .intro__summary__about .bar {
          width: 200px;
          height: 1px;
          border-bottom: solid 1px #29648a; }
      .homepage .intro__summary__quotes {
        padding: 10px;
        width: 100%; }
        @media all and (max-width: 768px) {
          .homepage .intro__summary__quotes {
            text-align: center; } }
        @media all and (max-width: 425px) {
          .homepage .intro__summary__quotes {
            font-size: .9rem; } }
        .homepage .intro__summary__quotes h5 {
          padding: 10px;
          text-align: right; }
      .homepage .intro__summary__actions {
        display: flex;
        width: 140px;
        margin: 100px auto 0 auto;
        justify-content: space-between;
        cursor: pointer; }
        .homepage .intro__summary__actions a {
          text-decoration: none;
          color: #aaabb8; }
  .homepage__creative {
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    min-height: 500px;
    max-height: 100%;
    background-color: #464866; }
    @media all and (max-width: 768px) {
      .homepage__creative {
        display: none; } }
    .homepage__creative img {
      display: block;
      margin: 0 auto;
      height: 100%;
      object-fit: cover;
      width: 100%; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.profile {
  background-color: #25274d;
  height: 100%;
  padding-top: 25px;
  box-sizing: border-box; }
  .profile__wrap {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center; }
    @media all and (max-width: 768px) {
      .profile__wrap {
        padding-top: 50px;
        flex-direction: column; } }
    .profile__wrap .side__bar {
      background-color: #29648a;
      position: absolute;
      opacity: .8;
      bottom: 5%;
      left: 25px;
      height: 90%;
      width: 1px; }
    .profile__wrap .dashed__bar {
      position: absolute;
      opacity: .8;
      top: 80px;
      left: 50%;
      height: 80%;
      border-left: dashed 1px #29648a;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    .profile__wrap .solid__bar {
      position: absolute;
      opacity: .8;
      top: 80px;
      left: 50.5%;
      height: 80%;
      border-left: solid 1px #29648a;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    .profile__wrap .bottom__bar {
      opacity: .8;
      position: absolute;
      color: #aaabb8;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 10px;
      width: 100%;
      bottom: 4.5%;
      margin: 0 10px; }
      .profile__wrap .bottom__bar .bar {
        width: 80%;
        height: 1px;
        background-color: #29648a; }
    @media all and (max-width: 768px) {
      .profile__wrap .dashed__bar, .profile__wrap .solid__bar {
        display: none; } }
    @media all and (max-width: 1024px) {
      .profile__wrap .side__bar, .profile__wrap .bottom__bar {
        display: none; } }
  .profile__description, .profile__skills {
    width: 50%;
    min-height: 500px;
    box-sizing: border-box; }
    @media all and (max-width: 768px) {
      .profile__description, .profile__skills {
        width: 100%;
        min-height: 350px; } }
  .profile__description {
    padding: 70px;
    display: flex;
    flex-direction: column;
    text-align: justify;
    color: #aaabb8; }
    @media all and (max-width: 1024px) {
      .profile__description {
        padding: 20px; } }
    .profile__description__title {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-bottom: 15px;
      text-align: center; }
      @media all and (max-width: 768px) {
        .profile__description__title {
          text-align: left; } }
      .profile__description__title h5 {
        margin-bottom: 10px; }
      .profile__description__title .bar {
        width: 100%;
        height: 1px;
        background-color: #29648a; }
    .profile__description__contacts {
      width: 100px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .profile__description__contacts a {
        color: #aaabb8; }
    .profile__description__summary {
      margin-bottom: 80px; }
      @media all and (max-width: 768px) {
        .profile__description__summary {
          margin-bottom: 50px; } }
    .profile__description__actions .button {
      padding: 0 15px;
      border: solid 2px #aaabb8;
      color: #aaabb8;
      font-weight: bold;
      background-color: transparent;
      cursor: pointer; }
    .profile__description__actions .ball {
      margin-top: 40px;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #29648a; }
      @media all and (max-width: 768px) {
        .profile__description__actions .ball {
          margin-top: 20px; } }
  .profile__skills {
    padding: 20px 0;
    padding-right: 70px;
    color: #aaabb8;
    height: 500px; }
    @media all and (max-width: 1024px) {
      .profile__skills {
        padding: 0 20px; } }
    @media all and (max-width: 768px) {
      .profile__skills {
        display: none; } }
    .profile__skills__title {
      opacity: .1;
      text-align: right;
      font-weight: bold; }
    .profile__skills__wrap {
      padding: 20px 10px 0px 15px;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between; }
      @media all and (max-width: 1024px) {
        .profile__skills__wrap {
          padding: 0px; } }
      .profile__skills__wrap h4 {
        position: absolute;
        text-align: right;
        margin-bottom: auto;
        font-weight: bold;
        right: calc( 100% + 5px); }
      .profile__skills__wrap h3:not(:last-child) {
        margin-bottom: 5px; }
      .profile__skills__wrap .improving, .profile__skills__wrap .skills, .profile__skills__wrap .languages {
        position: relative;
        width: 30%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box; }
      .profile__skills__wrap .improving {
        height: 30%;
        margin-top: 90px; }
      .profile__skills__wrap .skills {
        height: 60%; }
      .profile__skills__wrap .languages {
        height: 90%; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.tag {
  border-radius: 15px;
  padding: 5px 10px;
  background: #29648a;
  margin: 2px;
  font-size: .7rem;
  font-weight: bold;
  color: #aaabb8; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.tech-icon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 60px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .tech-icon img {
    height: 50px;
    width: auto;
    margin: 0 auto;
    display: block; }
  .tech-icon p {
    margin-top: 5px;
    text-align: center;
    font-weight: bold;
    font-size: .9rem; }

.carousel.carousel-slider {
  max-height: 300px;
  height: 100%;
  max-width: 550px;
  width: 100%;
  border-radius: 5px; }

.carousel {
  max-width: 550px;
  width: 100%; }

.slide img {
  border-radius: 5px;
  object-fit: cover;
  min-height: 100%;
  width: 100%; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.case-card {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex; }
  @media all and (max-width: 768px) {
    .case-card {
      flex-direction: column; } }
  .case-card__left, .case-card__right {
    margin-top: 30px;
    width: 50%;
    padding: 15px;
    box-sizing: border-box; }
    @media all and (max-width: 768px) {
      .case-card__left, .case-card__right {
        width: 100%; } }
  .case-card__title {
    color: #aaabb8;
    margin-bottom: 10px; }
  .case-card__tags {
    display: flex;
    margin-bottom: 30px; }
  .case-card__description, .case-card__technologies, .case-card__skills {
    color: #aaabb8;
    text-align: justify; }
    .case-card__description h3, .case-card__technologies h3, .case-card__skills h3 {
      margin-bottom: 10px;
      font-weight: bold; }
    .case-card__description p, .case-card__technologies p, .case-card__skills p {
      margin-bottom: 30px; }
  .case-card__technologies .tech-wrap {
    display: grid;
    grid-template-columns: 50px 50px 50px 50px 50px 50px 50px;
    grid-column-gap: 25px;
    grid-row-gap: 10px; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.contacts-sidebar {
  width: 50px;
  height: calc( 100vh - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed; }
  .contacts-sidebar .bar {
    height: 60%;
    background-color: #29648a;
    width: 1px; }
  .contacts-sidebar a {
    color: #aaabb8; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.case-study {
  background-color: #25274d;
  display: flex;
  padding-top: 35px;
  box-sizing: border-box;
  height: 100%;
  width: 100%; }
  @media all and (max-width: 1024px) {
    .case-study {
      height: auto; } }
  @media all and (max-width: 768px) {
    .case-study .contacts-sidebar {
      display: none; } }
  .case-study__content {
    padding: 50px;
    padding-top: 35px;
    height: 100%;
    box-sizing: border-box;
    width: 100%; }
    @media all and (max-width: 768px) {
      .case-study__content {
        padding: 20px;
        padding-top: 50px; } }
    .case-study__content__header {
      color: #aaabb8;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box; }
      .case-study__content__header h1 {
        font-weight: bold;
        opacity: .3; }
      .case-study__content__header a {
        color: #aaabb8;
        text-decoration: none; }
    .case-study__content .fade-enter {
      opacity: 0.01; }
    .case-study__content .fade-enter.fade-enter-active {
      opacity: 1;
      -webkit-transition: opacity 300ms ease-in;
      transition: opacity 300ms ease-in; }
    .case-study__content .fade-exit {
      opacity: 1; }
    .case-study__content .fade-exit.fade-exit-active {
      opacity: 0.01;
      -webkit-transition: opacity 300ms ease-in;
      transition: opacity 300ms ease-in; }
    .case-study__content--pointer {
      cursor: pointer; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.blog-page {
  height: 100%;
  background-color: #25274d;
  color: #aaabb8;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }
  @media all and (max-width: 768px) {
    .blog-page {
      flex-direction: column;
      justify-content: center; } }
  .blog-page h1 {
    font-size: 4rem; }
    @media all and (max-width: 1024px) {
      .blog-page h1 {
        font-size: 2rem; } }
  .blog-page img {
    max-height: 500px;
    max-width: 500px; }
    @media all and (max-width: 1024px) {
      .blog-page img {
        max-height: 300px;
        max-width: 300px;
        margin-top: 50px; } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

.default-layout {
  height: 100%;
  width: 100%; }
  @media all and (max-width: 1024px) {
    .default-layout {
      overflow-y: scroll; } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  overflow: hidden; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }

