@import "../../assets/css/_general";

.homepage {
  height: 100%;
  display: flex;

  @include md {
    flex-direction: column; }

  .intro {
    padding-top: 35px;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    min-height: 500px;
    background-color: $dark-lazulite;
    color: $light-grey;
    display: flex;

    @include md {
      width: 100%;
      order: 2; }

    &__contacts {
      min-width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .bar {
        height: 60%;
        background-color: $light-lazulite;
        width: 1px; }

      a {
        color: $light-grey; } }

    &__summary {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      padding: 40px 20px;
      box-sizing: border-box;
      max-height: 560px;
      height: 80%;

      &__about {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
        width: 100%;

        .bar {
          width: 200px;
          height: 1px;
          border-bottom: solid 1px $light-lazulite; } }

      &__quotes {
        padding: 10px;
        width: 100%;

        @include md {
          text-align: center; }

        @include sm {
          font-size: .9rem; }

        h5 {
          padding: 10px;
          text-align: right; } }



      &__actions {
        display: flex;
        width: 140px;
        margin: 100px auto 0 auto;
        justify-content: space-between;
        cursor: pointer;

        a {
          text-decoration: none;
          color: $light-grey; } } } }


  &__creative {
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    min-height: 500px;
    max-height: 100%;
    background-color: $dark-grey;

    @include md {
      display: none; }

    img {
      display: block;
      margin: 0 auto;
      height: 100%;
      object-fit: cover;
      width: 100%; } } }
