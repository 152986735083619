@import "../../assets/css/_general";

.tag {
  border-radius: 15px;
  padding: 5px 10px;
  background: $light-lazulite;
  margin: 2px;
  font-size: .7rem;
  font-weight: bold;
  color: $light-grey; }
