@import "./_reset";
@import "./_variables";
@import "./_colors";
@import "./_mixins";

@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

body {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

#app {
  width: 100%;
  height: 100vh; }
