@import "../../assets/css/_general";

.case-card {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;

  @include md {
    flex-direction: column; }

  &__left, &__right {
    margin-top: 30px;
    width: 50%;
    padding: 15px;
    box-sizing: border-box;

    @include md {
      width: 100%; } }

  &__title {
    color: $light-grey;
    margin-bottom: 10px; }

  &__tags {
    display: flex;
    margin-bottom: 30px; }

  &__description, &__technologies, &__skills {
    color: $light-grey;
    text-align: justify;

    h3 {
      margin-bottom: 10px;
      font-weight: bold; }

    p {
      margin-bottom: 30px; } }

  &__description {}

  &__technologies {
    .tech-wrap {
      display: grid;
      grid-template-columns: 50px 50px 50px 50px 50px 50px 50px;
      grid-column-gap: 25px;
      grid-row-gap: 10px; } }

  &__skills {} }
