@import './variables';

@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name)) + 1; }
  @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null; } }


@mixin xxs {
  @media all and (max-width: $screen-xxs) {
    @content; } }
@mixin xs {
  @media all and (max-width: $screen-xs) {
    @content; } }
@mixin sm {
  @media all and (max-width: $screen-sm) {
    @content; } }
@mixin md {
  @media all and (max-width: $screen-md) {
    @content; } }
@mixin lg {
  @media all and (max-width: $screen-lg) {
    @content; } }
@mixin xl {
  @media all and (max-width: $screen-xl) {
    @content; } }
@mixin xxl {
  @media all and (max-width: $screen-xxl) {
    @content; } }
