@import "../../assets/css/_general";

.blog-page {
  height: 100%;
  background-color: $dark-lazulite;
  color: $light-grey;
  // text-align: center
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include md {
    flex-direction: column;
    justify-content: center; }

  h1 {
    font-size: 4rem;

    @include lg {
      font-size: 2rem; } }

  img {
    max-height: 500px;
    max-width: 500px;

    @include lg {
      max-height: 300px;
      max-width: 300px;
      margin-top: 50px; } } }


