@import "../../assets/css/_general";

.case-study {
  background-color: $dark-lazulite;
  display: flex;
  padding-top: 35px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  @include lg {
    height: auto; }

  .contacts-sidebar {
    @include md {
      display: none; } }

  &__content {
    padding: 50px;
    padding-top: 35px;
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    @include md {
      padding: 20px;
      padding-top: 50px; }

    &__header {
      color: $light-grey;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;

      h1 {
        font-weight: bold;
        opacity: .3; }

      a {
        color: $light-grey;
        text-decoration: none; } }

    .fade-enter {
      opacity: 0.01; }

    .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 300ms ease-in; }

    .fade-exit {
      opacity: 1; }

    .fade-exit.fade-exit-active {
      opacity: 0.01;
      transition: opacity 300ms ease-in; }

    &--pointer {
      cursor: pointer; } } }
